.table-header-cip{
    color: #c82030 !important;
    text-align: center !important;
    font-weight: bold !important;
    font-size: 22px !important;
}

.required-cip{
    color: red;
}

.email-btn-cip{
    background-color: #99ca3c !important;
    color: white !important;
    font-family: 'Montserrat', sans-serif !important;
}

.email-btn-cip:hover {
    background-color: #2b383b !important;
    color: #99ca3c !important;
}

.clipboard-btn-cip{
    background-color: #00bff3 !important;
    color: white !important;
    font-family: 'Montserrat', sans-serif !important;
}

.clipboard-btn-cip:hover{
    background-color: #33393e !important;
    color: #00bff3 !important;
}

.divider-cip{
    margin-top: 2.5rem !important;
    margin-bottom: 2rem !important;
}

.clipboard-icon-cip{
    padding-left: 10px;
    color: black;
}

.email-icon-cip{
    padding-right: 20px;
    color: black;
}

.email-to-cip{
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 4px;
}

.grid-cip{
    height: 100% !important;
}

.cert-column-cip{
    padding: 5rem !important;
}

.no-cert-cip{
    margin-top: 6rem;
    margin-left: 22rem;
    margin-right: 22rem;
}

.bold-cell-cip{
    font-weight: bold;
}

.button-row-cip{
    padding-bottom: 2rem !important;
}

.power-icon-cip{
    color: rgba(0, 0, 0, 0.85) !important;
}


.email-error-popup-cip.ui.popup{
    background-color: #f86262 !important;
}

/*this is used to make the pointer of the pop-up the same color*/
.email-error-popup-cip.ui.popup:before{
    background-color: #f86262 !important;
}

#root > div > div > div.row.pt-component-row > div > div > div.ui.grid.grid-cip > div.no-cert-cip > div.segments.dark > div.ui.segment {
    background-color: #2b383b;
}

#root > div > div > div.row.pt-component-row > div > div > div.ui.grid.grid-cip > div.no-cert-cip > div.segments.dark > div.ui.segment > p {
    color: lightgrey;
}

div.grid-cip > div.cert-column-cip > div.segments.dark > div#ci-body-segment {
    background-color: #2b383b;
    color: lightgrey;
}

#root > div > div > div.row.pt-component-row > div > div > div.ui.grid.grid-cip > div.no-cert-cip > div.segments.dark > div.ui.segment {
    background-color: #2b383b;
}

#root > div > div > div.row.pt-component-row > div > div > div.ui.grid.grid-cip > div.no-cert-cip > div.segments.dark > div.ui.segment > p {
    color: lightgrey;
}

div.grid-cip > div.cert-column-cip > div.segments.dark > div#ci-body-segment {
    background-color: #23262b;
}

/*form inputs*/
div.segments.dark > #ci-body-segment > form > div.field > textarea,
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div.required.field > div > input#ci-email,
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div > div > input#ci-nerc {
    background-color: #353638;
    color: lightgrey;
}

div.segments.dark > #ci-body-segment > table {
    background-color: #353638;
    color: grey;
}

div.segments.dark > #ci-body-segment > table > tbody > tr > td.bold-cell-cip {
    color: lightgrey;
}

/*Form Labels*/
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div.required.field > label,
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div:nth-child(2) > label ,
div.segments.dark > #ci-body-segment > form > div.field > label {
    color: lightgrey;
}

/*Form Icons*/
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div.required.field > div > i,
div.segments.dark > #ci-body-segment > form > div.equal.width.fields > div:nth-child(2) > div > i {
    color: lightgrey !important;
}

/*Horizontal Divider*/
div.segments.dark > #ci-body-segment > div.ui.horizontal.divider.divider-cip,
div.segments.dark > #ci-body-segment > div > div > i.mail.icon.email-icon-cip,
div.segments.dark > #ci-body-segment > div > div > i.clipboard.icon.clipboard-icon-cip {
    color: lightgrey !important;
}
