
.background-image-div {
    height: calc(100vh - (130px + 28px));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

/*Mobile and tablet css*/
@media screen and (max-width: 768px) and (orientation: portrait) {
    .background-image-div {
        background-image: url("/public/images/mobile-portal-split-background.png") !important;
    }

    #home-divider-icon {
        transform: translateY(-20px) rotate(90deg);
    }

    #public-column-wrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 769px) {
    #home-divider-icon {
        transform: translateX(-5px);
    }
}

#home-divider-icon {
    position: absolute;
    inset: 0 ;
    margin: auto ;
    vertical-align: middle ;
    color: #c82030 ;
}

#home-logo-grid {
    height: 100%;
}

#im-logo-row {
    padding-bottom: 4%;
    padding-top: 8%;
}

#im-logo-img {
    max-width: 80rem;
    width: 70%;
}

#im-btn-row {
    padding-bottom: 0;
}

#weis-logo-row {
    padding-top: 2%;
}

#weis-logo-img {
    width: 80%;
    max-width: 90rem;
}

#west-see-graph-btn {
    width: 17rem;
    box-shadow: 0 0 0 2px #c82030 inset !important;
    border-radius: 1.5rem;
    font-weight: 800;
    font-size: 1em;
    font-family: museo-sans,"Helvetica Neue",Helvetica,sans-serif;
}

#im-see-graph-btn {
    width: 17rem;
    box-shadow: 0 0 0 2px whitesmoke inset !important;
    border-radius: 1.5rem;
    font-weight: 800;
    font-size: 1em;
    font-family: museo-sans,"Helvetica Neue",Helvetica,sans-serif;

}

#im-see-graph-btn:hover {
    background-color: #5c6971 !important;
}

#dashboard-title {
    font-size: 24px;
    font-weight: 700;
    color: #2b383b;
}

#dashboard-title.dark {
    font-size: 24px;
    font-weight: 700;
    color: lightgrey;
}

.db-chart-row {
    height: calc((100vh - (130px + 28px + 3.3rem + 3.8rem))/2);
    min-height: 250px;
}

.db-chart-single-row {
    height: calc(100vh - (130px + 28px + 3.3rem + 3.8rem));
    min-height: 250px;
}

#db-title-row {
    padding-bottom: 0;
}

#chart-grid-col.dark > div.chart-sg > div.chart-segment,
#im-graph-grid > div.two.column.row.db-chart-row > div.six.wide.column.dark > div > div.ui.segment.chart-segment,
#im-graph-grid > div.one.column.row.db-chart-row > div.column.dark > div.chart-sg > div.ui.segment.chart-segment,
#im-graph-grid > div.two.column.row.db-chart-row > div.ten.wide.column.dark > div > div.ui.segment.chart-segment
{
    background-color: #23262b;
}

#chart-grid-col.dark > div.chart-sg > div.chart-segment.tableau,
#im-graph-grid > div.two.column.row.db-chart-row > div.six.wide.column.dark > div > div.ui.segment.chart-segment.tableau,
#im-graph-grid > div.one.column.row.db-chart-row > div.column.dark > div.chart-sg > div.ui.segment.chart-segment.tableau,
#im-graph-grid > div.two.column.row.db-chart-row > div.ten.wide.column.dark > div > div.ui.segment.chart-segment.tableau
{
    background-color: white !important;
}




.chart-column {
    height: 100%;
}
