#footer-menu{
    background-color: #33393e;
    margin: -10px;
    position: fixed;
    bottom: 10px;
    right:0;
    border-radius: 0;
}

#footer-menu .footer-item{
    color: white
}

#footer-menu .footer-item #footer-right{
    position: fixed;
    right: 20px
}

#footer-right a{
    color: white;
}