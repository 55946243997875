.rail-menu-item {
    color: white !important;
    cursor: pointer;
}

.rail-menu-item.ribbon {
    display: block !important;
    width: 200px;
}

.rail-menu-item.header {
    text-transform: uppercase !important;
    font-size: 18px;
    padding-left: 1rem !important;
    padding-bottom: .5rem !important;
}

#menu-rail {
    width: 100%;
    z-index: 10000;
}

#submenu-segment {
    background-color: #c82030;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#menu-grid {
    padding-left: 2%;
    padding-bottom: 1.5%;
    padding-right: 2%;
    min-height: 170px;
}

.rail-sub-menu {
    background-color: #c82030 !important;
    box-shadow: none !important;
    border: none !important;
}

.rail-submenu-icon-column{
    width: 1% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.bold-on-hover-selected {
    font-weight:1000 !important;
    font-size: 16px;
}

.bold-on-hover:hover {
    font-weight:1000 !important;
    font-size: 16px;
}

.rail-menu-column {
    box-shadow: none !important;

}