#embedded-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: .5rem;
    color: #2b383b;
}

#em-title-row {
    padding-bottom: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
}

#em-title-row > #breadcrumb {
    padding-top: 5px;
}

#external-app-btn {
    float: right;
    color: rgb(128, 128, 128);
    font-size: 20px;
    cursor: pointer;
}

#external-app-icon {
    padding-left: .5rem;
}

#title-label {
    position: absolute;
    right: 10px;
    bottom: 0;
    cursor: pointer;
}

#favorite-label {
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
    color: #2b383b;
}

#breadcrumb-label {
    position: absolute;
    left: 10px;
    bottom: 0;
    cursor: pointer;
    color: #2b383b;
}

@media screen and (max-width: 768px){
    #breadcrumb-label{
        display: none;
    }
}

#breadcrumb-label > a {
    color: #2b383b !important;
}

#breadcrumb-label > span {
    color: grey;
}

#embedded-grid-column.dark > #em-title-row > #breadcrumb > a,
#embedded-grid-column.dark > #em-title-row > #breadcrumb {
    color: #a9a9a9;
}


#embedded-grid-column.dark > #em-title-row > #breadcrumb > a:hover {
    color: lightgrey;
}

#embedded-frame {
    background-color: #f1f1f1;
    width: 100%;
}

#embedded-frame.no-title {
    padding-left: .9rem;
    padding-right: 1rem;
}

#embedded-frame.dark {
    background-color: #4b5458;
}


#embedded-title.dark  {
    color: lightgrey !important;
}

#embedded-title.dark > #external-app-btn  {
    color: lightgrey !important;
}