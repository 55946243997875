#page-title h1{
    font-size: 30px;
    text-transform: uppercase;
}

#page-title p{
    font-size: 18px;
    padding-bottom: 30px;
}

#page-title-not-visible h1{
    min-height: 0;
    font-size: 30px;
    text-transform: uppercase;

}

#page-title-not-visible p{
    padding-bottom: 0;
}

