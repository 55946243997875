.spp-icon {
    font-size: 20px;
}
.spp-icon.folder {
    display: inline-block;
    margin: 1.4em .8em 1em 1.3em;
    background-color: transparent;
    overflow: hidden;
}
.spp-icon.folder:before {
    content: '';
    float: left;
    background-color: #7ba1ad;

    width: 1.5em;
    height: 0.45em;

    margin-left: 0.07em;
    margin-bottom: -0.07em;

    border-top-left-radius: 0.1em;
    border-top-right-radius: 0.1em;

    box-shadow: 1.25em 0.25em 0 0 #7ba1ad;
}
.spp-icon.folder:after {
    content: '';
    float: left;
    clear: left;

    background-color: #a0d4e4;
    width: 3em;
    height: 2.25em;

    border-radius: 0.1em;
}
.spp-icon.folder.full:before {
    height: 0.55em;
}
.spp-icon.folder.full:after {
    height: 2.15em;
    box-shadow: 0 -0.12em 0 0 #ffffff;
}

.spp-icon.file {
    width: 2.5em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    border-radius: 0.25em;
    color: #FFF;
    display: inline-block;
    margin: 1.5em 0.8em 0.8em 1.3em;
    position: relative;
    overflow: hidden;
    box-shadow: 1.74em -2.1em 0 0 #A4A7AC inset;
}
.spp-icon.file:first-line {
    font-size: 13px;
    font-weight: 700;
}
.spp-icon.file:after {
    content: "";
    position: absolute;
    z-index: -1;
    border-width: 0;
    border-bottom: 2.6em solid #DADDE1;
    border-right: 2.22em solid rgba(0, 0, 0, 0);
    top: -34.5px;
    right: -4px;
}

.spp-icon.file.f-avi,
.spp-icon.file.f-flv,
.spp-icon.file.f-mkv,
.spp-icon.file.f-mov,
.spp-icon.file.f-mpeg,
.spp-icon.file.f-mpg,
.spp-icon.file.f-mp4,
.spp-icon.file.f-m4v,
.spp-icon.file.f-wmv {
    box-shadow: 1.74em -2.1em 0 0 #7e70ee inset;
}
.spp-icon.file.f-avi:after,
.spp-icon.file.f-flv:after,
.spp-icon.file.f-mkv:after,
.spp-icon.file.f-mov:after,
.spp-icon.file.f-mpeg:after,
.spp-icon.file.f-mpg:after,
.spp-icon.file.f-mp4:after,
.spp-icon.file.f-m4v:after,
.spp-icon.file.f-wmv:after {
    border-bottom-color: #5649c1;
}

.spp-icon.file.f-mp2,
.spp-icon.file.f-mp3,
.spp-icon.file.f-m3u,
.spp-icon.file.f-wma,
.spp-icon.file.f-xls,
.spp-icon.file.f-xlsx {
    box-shadow: 1.74em -2.1em 0 0 #5bab6e inset;
}
.spp-icon.file.f-mp2:after,
.spp-icon.file.f-mp3:after,
.spp-icon.file.f-m3u:after,
.spp-icon.file.f-wma:after,
.spp-icon.file.f-xls:after,
.spp-icon.file.f-xlsx:after {
    border-bottom-color: #448353;
}

.spp-icon.file.f-doc,
.spp-icon.file.f-docx,
.spp-icon.file.f-psd{
    box-shadow: 1.74em -2.1em 0 0 #03689b inset;
}

.spp-icon.file.f-doc:after,
.spp-icon.file.f-docx:after,
.spp-icon.file.f-psd:after {
    border-bottom-color: #2980b9;
}

.spp-icon.file.f-gif,
.spp-icon.file.f-jpg,
.spp-icon.file.f-jpeg,
.spp-icon.file.f-pdf,
.spp-icon.file.f-png {
    box-shadow: 1.74em -2.1em 0 0 #e15955 inset;
}
.spp-icon.file.f-gif:after,
.spp-icon.file.f-jpg:after,
.spp-icon.file.f-jpeg:after,
.spp-icon.file.f-pdf:after,
.spp-icon.file.f-png:after {
    border-bottom-color: #c6393f;
}

.spp-icon.file.f-deb,
.spp-icon.file.f-dmg,
.spp-icon.file.f-gz,
.spp-icon.file.f-rar,
.spp-icon.file.f-zip,
.spp-icon.file.f-7z {
    box-shadow: 1.74em -2.1em 0 0 #867c75 inset;
}
.spp-icon.file.f-deb:after,
.spp-icon.file.f-dmg:after,
.spp-icon.file.f-gz:after,
.spp-icon.file.f-rar:after,
.spp-icon.file.f-zip:after,
.spp-icon.file.f-7z:after {
    border-bottom-color: #685f58;
}

.spp-icon.file.f-html,
.spp-icon.file.f-rtf,
.spp-icon.file.f-xml,
.spp-icon.file.f-xhtml {
    box-shadow: 1.74em -2.1em 0 0 #a94bb7 inset;
}
.spp-icon.file.f-html:after,
.spp-icon.file.f-rtf:after,
.spp-icon.file.f-xml:after,
.spp-icon.file.f-xhtml:after {
    border-bottom-color: #d65de8;
}

.spp-icon.file.f-js {
    box-shadow: 1.74em -2.1em 0 0 #d0c54d inset;
}
.spp-icon.file.f-js:after {
    border-bottom-color: #a69f4e;
}

.spp-icon.file.f-css,
.spp-icon.file.f-saas,
.spp-icon.file.f-scss {
    box-shadow: 1.74em -2.1em 0 0 #44afa6 inset;
}
.spp-icon.file.f-css:after,
.spp-icon.file.f-saas:after,
.spp-icon.file.f-scss:after {
    border-bottom-color: #30837c;
}

.item-name {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    word-wrap: break-word;
    position: absolute;
    top: 35px
}

.item-info {
    position: absolute;
    top: 75px;
    font-size: 13px;
}

.folder-segment:hover {
    background-color: #838383 !important;
    color: white !important;
    cursor: pointer;
}

#fb-breadcrumb-roll {
    margin-bottom: 1.5rem
}

.fb-icon {
    color: #2b383b
}

#fb-icon-menu > div > a.disabled.item > i {
    opacity: .25;
}

#fb-icon-menu {
    margin-bottom: 0
}

#fb-title-column {
    padding-right: 5rem;
    padding-left: 5rem;
    padding-bottom: 5rem;
    padding-top: 3rem;
}

#fb-arrow-icon {
    color: #c82030;
    margin-bottom: 5px
}

#fb-summary-btn {
    text-align: left;
    background-color: lightgray;
    color: #2b383b
}

.file-segment {
    z-index: 1;
    background-color: #f1f1f1;
    position: relative;
}

#file-info-div {
    position: absolute;
    right: 10px;
    bottom: 10px;
}

#file-icon-column {
    padding-top: .5rem;
    padding-bottom: 1rem
}

.folder-segment {
    background-color: #f1f1f1
}

.file-segment:hover {
    background-color: #838383 !important;
    color: white !important;
    cursor: pointer;
}

#folder-name {
    font-size: 24px;
    font-weight: 700;
    color: #2b383b;
    background-color: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0;
    padding-top: 0;
}

#file-browser-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 2rem;
    color: #2b383b;
}

.fb-info-btn {
    text-align: left !important;
    background-color: lightgrey;
    color: #2b383b;
}

.nothingfoundtrue {
    background-color: #373743;
    width: 23em;
    height: 21em;
    visibility: hidden;
    margin-bottom: 50px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    -webkit-animation: showSlowlyElement 700ms; /* Chrome, Safari, Opera */
    animation: showSlowlyElement 700ms; /* Standard syntax */
}

.nothingfoundtrue .nofiles {
    margin: 30px auto;
    top: 3em;
    visibility: hidden;
    border-radius: 50%;
    position:relative;
    background-color: #c82030;
    width: 11em;
    height: 11em;
    line-height: 11.4em;
}
.nothingfoundtrue .nofiles:after {
    content: '×';
    position: absolute;
    color: #ffffff;
    visibility: hidden;
    font-size: 14em;
    margin-right: 0.1em;
    margin-bottom: .04em;
    right: 0;
    bottom: 0;
}

.nothingfoundtrue span {
    margin: 0 auto auto 7.5em;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    visibility: hidden;
    line-height: 20px;
    height: 13px;
    position: relative;
    top: 2em;
}

.nothingfoundfalse {
    background-color: #373743;
    width: 23em;
    height: 21em;
    margin-bottom: 50px;
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
    -webkit-animation: showSlowlyElement 700ms; /* Chrome, Safari, Opera */
    animation: showSlowlyElement 700ms; /* Standard syntax */
}

.nothingfoundfalse .nofiles {
    margin: 30px auto;
    top: 3em;
    border-radius: 50%;
    position:relative;
    background-color: #c82030;
    width: 11em;
    height: 11em;
    line-height: 11.4em;
}
.nothingfoundfalse .nofiles:after {
    content: '×';
    position: absolute;
    color: #ffffff;
    font-size: 14em;
    margin-right: 0.1em;
    margin-bottom: .04em;
    right: 0;
    bottom: 0;
}

.nothingfoundfalse span {
    margin: 0 auto auto 7.5em;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    height: 13px;
    position: relative;
    top: 2em;
}

#file-browser-segment {
    margin-top: 0;
    max-height: 740px;
    overflow: auto;
}

#fb-title-column.dark > #file-browser-segment > #fb-icon-menu > div > a:nth-child(1) > i {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > #fb-icon-menu > div > a:nth-child(2) > i  {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > #fb-breadcrumb-roll > #folder-name {
    background-color: #23262b;
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.folder-segment {
    background-color: #353638;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.folder-segment > span.item-name {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.file-segment {
    background-color: #353638;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.file-segment > div > div.column {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.file-segment > span.item-name {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment > div > div > div.fb-segment-row > div.column.dark > div.file-segment > div#file-info-div {
    color: lightgrey;
}

