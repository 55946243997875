.pgap-child-btn {
    font-size: 20px !important;
    font-weight: 700 !important;
    padding: 0 !important;
    background-color: transparent !important;
    text-align: left !important;

}

.pgap-child-btn-dark {
    font-size: 20px !important;
    font-weight: 700 !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: #d3d3d3 !important;
    text-align: left !important;
}

.pgap-menu-option:hover .pgap-child-btn-dark,
.pgap-menu-option:hover .pgap-child-btn {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #c82030 !important;
    padding: 0 !important;
    background-color: transparent !important;
}

.pgap-favorite {
    display: none;
}

.pgap-menu-option {
    display: inline-block;
    background-color: transparent !important;
}

.pgap-menu-option:hover .pgap-favorite{
    display: inline !important;
}

#fb-title-column.dark > #file-browser-title {
    color: lightgrey;
}

#fb-title-column.dark > #file-browser-segment {
    background-color: #23262b;
}

#fb-title-column.dark > #file-browser-segment > div > div > a > .pgap-child-btn {
    background-color: #23262b !important;
    color: lightgrey;
}

#fb-title-column > #file-browser-segment > div > div > span > font,
#fb-title-column > #file-browser-segment > div > div > p,
#fb-title-column > #file-browser-segment > div > div > span {
    color: grey !important;
}

#fb-title-column.dark > #file-browser-segment {
    color: grey;
}

#fb-title-column.dark > div.ui.attached.segment {
    background-color: grey
}

#breadcrumb {
    position: absolute;
    top: 0;
    color: grey;
}

@media screen and (max-width: 768px){
    #breadcrumb{
        display: none;
    }
}


#breadcrumb > a {
    color: grey;
}

#breadcrumb > a:hover {
    color: #2b383b;
}

#fb-title-column.dark > #breadcrumb > a,
#fb-title-column.dark > #breadcrumb {
    color: #a9a9a9;
}

#fb-title-column.dark > #breadcrumb > a:hover {
    color: lightgrey;
}

@media screen and (min-height: 848px) {
    .background-image-pg-as-page {
        height: calc(100vh - (130px + 28px));
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }

}

@media screen and (min-height: 0) {

    .background-image-pg-as-page {
        min-height: 700px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    }
}

div.pgap-nav-link > button {
    text-align: left;
}