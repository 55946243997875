#page-child-odd {
    background-color: lightgrey;
}

#page-group-child-odd {
    background-color: lightgray;
}

#page-group-children-grid {
    padding-top: 1rem;
}

#page-group-children-grid > div.row {
    padding-bottom: .5rem;
}

#page-children-grid {
    padding-top: 1rem;
}

#page-children-grid > div.row {
    padding-bottom: .5rem;
}

#page-group-child-even.selected {
    background-color: royalblue;
}

#page-group-child-odd.selected {
    background-color: royalblue;
}

#page-child-even.selected {
    background-color: royalblue;
}

#page-child-odd.selected {
    background-color: royalblue;
}

button.child-button-group {
    margin-right: 0 !important;
}

#pg-child-card.selected {
    display: initial;
}

#pg-child-card {
    display: none;
}