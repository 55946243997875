.fav-page-group-child, .fav-page-group-child-dark{
    z-index: 1;
    background: transparent !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
}

.fav-page-group-child{
    color: #c2081a !important;
}

.fav-page-group-child-dark{
    color: #da1f31 !important;
}

.fav-page-group, .fav-page-group-dark {
    z-index: 1;
    background: transparent !important;
    right: 3.9rem;
    position: absolute;
    top: -7px;
}

.fav-page-group{
    color: #152427 !important;
}

.fav-page-group-dark{
    color: #e2e1e0 !important;
}

.fav-static-fb, .fav-static-fb-dark{
    z-index: 1;
    background: transparent !important;
    right: 2.9rem;
    padding-top: 19px !important;
    position: absolute;
}

.fav-static-fb{
    color: #152427 !important;
}

.fav-static-fb-dark{
    color: #e2e1e0 !important;
}

.fav-embedded-no-title{
    z-index: 1;
    background: transparent !important;
    padding: 0 0 0 8px !important;
    margin: 0 !important;
    color: #162528 !important;
}

.fav-embedded-with-title, .fav-embedded-with-title-dark{
    z-index: 1;
    background: transparent !important;
    right: 0.2rem;
    position: absolute;
    top: -2px;
}

.fav-embedded-with-title{
    color: #152427 !important;
}

.fav-embedded-with-title-dark{
    color: #e2e1e0 !important
}