.favorite-icon:hover {
    cursor: pointer;
}

#static-chart-column {
    padding-right: 5rem;
    padding-left: 5rem;
    padding-top: 3rem;
}

#static-chart-row {
    height: 400px;
}

.download-button-row {
    padding: 1rem;
}

#static-chart-column.dark > div > #static-body-sg {
    background-color: #23262b;
}

#static-chart-column.dark > div > #static-body-sg > div > div:nth-child(1) > div:nth-child(1) > p,
#static-chart-column.dark > div > #static-body-sg > div > div:nth-child(1) > div:nth-child(1)
{
    color: grey;
}

#static-dl-btn.dark {
    background-color: #2b383b;
    color: lightgrey;
}

#static-dl-btn-gp > div > button.dark {
    background-color: #2b383b;
    color: lightgrey;
}

div.background-image-div.dark-image  > div > div > div#static-chart-column > #breadcrumb > a,
div.background-image-div.dark-image  > div > div > div#static-chart-column > #breadcrumb {
    color: #a9a9a9;
}

div.background-image-div.dark-image  > div > div > div#static-chart-column > #breadcrumb > a:hover {
    color: lightgrey;
}

div.background-image-div.light-image  > div > div > div#static-chart-column.dark > #breadcrumb > a,
div.background-image-div.light-image  > div > div > div#static-chart-column.dark > #breadcrumb {
    color: #a9a9a9;
}

div.background-image-div.light-image  > div > div > div#static-chart-column.dark > #breadcrumb > a:hover {
    color: lightgrey;
}