.centered-error {
    height: 150px;
    line-height: 150px;
    text-align: center;
}

.upper-text span {
    font-size: 10em;
    padding: 10px;
}

.middle-text span {
    font-size: 8em;
    padding: 10px;
}

.lower-text span {
    font-size: 4em;
}

.center-link {
    height: 50px;
    line-height: 50px;
    text-align: center;
}