#sm-column {
    padding: 5rem;
}

#sm-body-sg {

    min-height: 300px;
    overflow: auto;
}

.sm-list.link:hover > i {
    color: #c82030 !important;
}

.sm-list.link:hover > div.content > div.header {
    color: #c82030 !important;
}

#sm-seg.dark > #sm-body-sg {
    background-color: #23262b;
}

#sm-seg-legend.dark > #sg-legend-bdy {
    background-color: #23262b;
    border: solid lightgrey;
}

#sm-seg-legend.dark > #sg-legend-bdy > div > div > i,
#sm-seg-legend.dark > #sg-legend-bdy > div > div > div.content
{
    color: lightgrey  ;
}

div.ui.list.dark > div.item,
div.ui.list.dark > div > div > div.header,
div.list.dark > div > div > div.header,
div.list.dark > a > div > div,
div.list.dark > a > i,
div.list.dark > div.sm-list > i
{
    color: lightgrey !important;
}

.icon.list {
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}

.legend-selected > div.content > div.header,
div.list.dark > div.item.legend-selected > div.content > div.header
{
    color: #2185d0 !important;
}

.legend-item > i {
    color: grey
}


.item.sm-list > i {
    color: #2b383b;
}

.legend-item {
    cursor: pointer;
    font-weight: bold;
}

.legend-selected > div.content {
    color: #2185d0 !important;
}

#sm-seg-legend.legend {
    position: fixed;
    top: 250px;
    right: 150px;
}

#sitemap-grid {
    height: 100%;
}

#top-level-bkg.dark {
    background-color: #4b5458
}

#top-level-bkg.light {
    background-color: #f1f1f1
}

