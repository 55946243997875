.common{
    margin: -10px;
}

.common.admin {
    background-color: #2b383b;
}

.common img{
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 10px;
}

#header-menu{
    border-radius: 0;
    background-color: #c82030;
}

#app-info-row {
    padding: 0
}

#nav-menu{
    padding-top: 80px;
    background-color: #2b383b
}

#header-menu.non-visible{
    display: none;
}

#image-surround{
    width: 270px
}

#image-header-row{
    padding-bottom: 10px
}

#image-column{
    z-index: 1000;
}

#spp-logo {
    max-width: 100%
}

#app-menu-row{
    padding: 0;
}