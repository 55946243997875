.im-chart-row {
    height: calc((100vh - (130px + 28px + 3.3rem + 3.8rem))/2);
    min-height: 250px;
}

.chart-sg {
    height: 100%;
}

#im-char-logo-row {
    padding-bottom: 0;
    height: 3.3rem;
}

.im-background-image-div {
    height: calc(100vh - (130px + 28px));
    min-height: 660px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: #5c6971
}

#im-graph-grid {
    height: 100%;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

#dark-last-modified-column {
    color: darkgrey;
}

#im-graph-grid > div > div.column.dark > div > div.ui.segment.chart-segment {
    background-color: #23262b;
}