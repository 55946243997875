#footer-menu{
    background-color: #2b383b;
    margin: -10px;
    position: fixed;
    bottom: 10px;
    right:0;
    border-radius: 0;
    height: 42px;
    z-index: 1000;
}

#footer-menu .footer-item{
    color: lightgrey;
}



#footer-menu .footer-item #footer-right{
    position: fixed;
    right: 20px
}

#footer-right a{
    color: white;
}

.footer-spacer {
    padding: .25rem;
}

.footer-link:hover {
    color: lightgrey !important;
}
