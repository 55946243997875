.ab-menu-item{
    color: rgba(0,0,0,0.6) !important;
    font-weight: bold !important;
}

.ab-menu-item.button {
    padding: 0 !important;
}

.ab-menu{
    box-shadow: none !important;
    background-color: #E0E1E2 !important;
    border: 0 !important;
}

#user-action-bar{
    min-width: max-content;
}


@media only screen and (max-width: 1100px) {
    #user-action-bar.ui.stackable.menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    #user-action-bar.ui.stackable.menu .item {
        width: 100% !important;
    }
    #user-action-bar.ui.stackable.menu .item:before {
        position: absolute;
        content: '';
        top: auto;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: rgba(34, 36, 38, 0.1);
    }
    #user-action-bar.ui.stackable.menu .left.menu,
    #user-action-bar.ui.stackable.menu .left.item {
        margin-right: 0 !important;
    }
    #user-action-bar.ui.stackable.menu .right.menu,
    #user-action-bar.ui.stackable.menu .right.item {
        margin-left: 0 !important;
    }
    #user-action-bar.ui.stackable.menu .right.menu,
    #user-action-bar.ui.stackable.menu .left.menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) {
    #user-action-bar-lsa.ui.stackable.menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    #user-action-bar-lsa.ui.stackable.menu .item {
        width: 100% !important;
    }
    #user-action-bar-lsa.ui.stackable.menu .item:before {
        position: absolute;
        content: '';
        top: auto;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 1px;
        background: rgba(34, 36, 38, 0.1);
    }
    #user-action-bar-lsa.ui.stackable.menu .left.menu,
    #user-action-bar.ui.stackable.menu .left.item {
        margin-right: 0 !important;
    }
    #user-action-bar-lsa.ui.stackable.menu .right.menu,
    #user-action-bar.ui.stackable.menu .right.item {
        margin-left: 0 !important;
    }
    #user-action-bar-lsa.ui.stackable.menu .right.menu,
    #user-action-bar-lsa.ui.stackable.menu .left.menu {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
}

