#static-chart-column.dark > #breadcrumb > a,
#static-chart-column.dark > #breadcrumb > span {
    color: #a9a9a9;
}

#static-chart-column.dark > #breadcrumb > a:hover {
    color: lightgrey;
}

.tableau-dimmer {
    background-color: #ffffff !important;
}

