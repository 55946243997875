#west-chart-row {
    height: calc((100vh - (130px + 28px + 3.3rem + 3.8rem))/2);
    min-height: 250px;
}

.chart-segment {
    height: calc(100% - (2.5rem + 2rem));
}

#weis-chart-grid {
    height: 100%;
    padding-right: 2rem !important;
    padding-left: 2rem !important;
}

#west-chart-logo {
    width: 17rem;
    height: 5rem;
}

#west-char-logo-row {
    height: 6rem;
}


.chart-title-sg{
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
    background-color: lightgrey !important;
    font-weight: bold;
    height: 2.5rem !important;
}

.narrow-btn {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important;
}

.graph-btn {
    text-align: left !important;
    height: 2rem !important;
}

#back-btn-row {
    padding-bottom: 1rem;
    height: 3.8rem;
    padding-top: 0;
}

.weis-chart-div {
    min-height: 430px
}

.see-more-arrow {
    float: right;
}

#light-last-modified-column {
    color: grey
}

#west-chart-row > div.dark> div > div.ui.segment.chart-segment {
    background-color: #23262b;
}
