.tou-btn {
    background-color: #99ca3c !important;
    height: 2rem !important;
}

.tou-btn:hover {
    background-color: #2b383b !important;
    color: #99ca3c !important;
}

.background-image-tou {
    min-height: calc(100vh - (130px + 28px));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.tou-header {
    color: #e51837;
    font-family: "Segoe UI Semilight", sans-serif;
    font-size: 20px;

}

.tou-body {
    color: #2b383b;
    font-family: "Segoe UI", sans-serif;
    font-size: 16px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

#tou-sg.dark > #tou-body-sg {
    background-color: #23262b;
}

#tou-sg.dark > #tou-body-sg > p {
    color: lightgrey;
}