#cp-segment-header {
    background-color: darkgray;
    font-size: 1.2rem;
    line-height: 1.28571429em;
    font-weight: 700;
}

#cp-segment-group {
    margin-bottom: 1rem;
}

#cp-cancel-button {
    margin-right: 1.1rem;
    width: 22rem;
}

#cp-submit-button {
    width: 22rem;
}