#db-settings-body > #paginated-table-grid > #action-bar-table-buttons-row {
    padding-top: 0;
}

#db-setting-return-btn {
    color: white;
    background-color: #2b383b;
    width: 22rem;
    margin-right: 2rem
}

#db-setting-button-wrapper {
    text-align: right;
    padding-top: 2rem;
    padding-bottom: 1rem
}

#db-settings-theme-toggle-menu {
    margin-bottom: 0;
    margin-top: 0
}

#db-settings-theme-toggle {
    padding-right: 0;
}

#db-settings-body.dark {
    background-color: #23262b;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table > thead > #column-header > th {
    color: white !important;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table > tbody > tr > td {
    color: white !important;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table {
    background-color: #353638 !important;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table > tbody > tr > td {
    border-left-color: grey;
    border-top-color: grey;
    border-bottom-color: grey;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table > thead > #column-header > th {
    border-left-color: grey;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-wrapper > #super-table > thead > #column-header {
    border-left-color: grey;
    box-shadow: 0 2px 15px 0 darkgrey;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row {
    background-color: #191a1c;
    border: none !important;
}

#db-settings-body.dark > div > form > div > div.six.wide.field > div {
    background-color: #353638 !important;
}

#db-settings-body.dark > div > form > div > div.ten.wide.field > div {
    background-color: #353638 !important;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row > div > #page-number-span > div > #page-input {
    background-color: #353638 !important;
    color: grey;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row > div.center > div {
    background-color: #353638 !important;
    color: grey;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row > div.previous > button{
    background-color: #353638 !important;
    color: white;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row > div.next > button{
    background-color: #353638 !important;
    color: white;
}

#db-settings-body.dark > div > #paginated-table-grid > #react-table-row > #react-table-grid > #paginated-table-wrapper > #table-footer > #pagination-row > div > #page-number-span {
    color: grey;
}

#db-settings-body.dark > div > div.ui.secondary.menu > div > div:nth-child(1) {
    color: grey;
}

#db-settings-body.dark > div > form > div > div.six.wide.field > label {
    color: grey;
}

#db-settings-body.dark > div > form > div > div.ten.wide.field > label {
    color: grey;
}

#db-settings-body.dark > div > form > div > div.six.wide.field > div {
    color: grey;
}

#db-settings-body.dark > div > form > div > div.ten.wide.field > div {
    color: grey;
}

#db-settings-body.dark > div > form > div > div.six.wide.field > div > div.menu.transition {
    background-color: grey;
}

#db-settings-body.dark > div > form > div > div.ten.wide.field > div > div.menu.transition {
    background-color: grey;
}

#db-segment-group{
    margin: 5rem
}

#db-body-wrapper {
    padding:  1rem;
}

.db-settings-button {
    width: 22rem
}

.rt-noData.db-settings-table {
    position: absolute;
    left: 47%;
    top: 50%;
    color: rgba(0,0,0,.5);
    background: hsla(0,0%,100%,.8);
    padding: 10px;
    z-index: 1;
}

#db-settings-body.dark > div > div> #chart-preference-table {
    background-color: #353638;
    color: grey;
}

#db-settings-body.dark > div > div> #chart-preference-table > thead > tr > th {
    color: lightgrey;
}

