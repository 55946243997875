#header-menu{
    background-color: #c82030;
}

.underline-on-hover::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: 0px;
    left: 0;
    background-color: white;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.underline-on-hover:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.underline-on-hover-selected::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 5px;
    bottom: 0;
    left: 0;
    background-color: white !important;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.underline-on-hover-selected::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.underline-on-hover.selected {
    border-bottom: 5px solid white !important;
}

.link-menu {
    font-size: 20px;
    color: grey !important;
    font-weight: 400;
}

#environment-version {
    margin-right: .5rem;
    padding: 0;
    cursor: default;
}

#link-menu {
    border: none !important;
    box-shadow: none !important;
    background-color: #2b383b !important;
}

.application-info {
    background-color: #2b383b !important;
    color: grey !important;
    font-size: large !important;

}

#environment-version-divider {
    padding: 0;
}

#sign-in-button {
    padding: 0;
    margin-right: 2rem;
    margin-left: .5rem;
}

#app-menu-row {
    background-color: #c82030;
    padding: 0;
    margin-bottom: 1rem;
}

#public-spp-logo {
    width: 15rem;
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 0;
}

#common-header-row {
    padding-bottom: 0;
    height: 90px;
    background-color: #2b383b;
}

#condensed-menu {
    padding-left: 2rem;
    color: white;
}

.dark-mode-toggle {
    position: absolute;
    right: 30px;
    bottom: 15px;
    color: #c82030 !important;
}