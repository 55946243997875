#tbg-create-button {
    margin-right: .7rem;
    background-color: darkseagreen;
}

#tbg-refresh-button {
    margin-right: .7rem;
    background-color: royalblue;
}

#tbg-download-button {
    margin-right: .7rem;
    background-color: darkgrey;
}