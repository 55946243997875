.ab-menu-item.button {
    padding: 0 !important;
}

.ab-menu{
    box-shadow: none !important;
    background-color: #E0E1E2 !important;
    border: 0 !important;
}

.ab-menu-item{
    color: rgba(0,0,0,0.6) !important;
    font-weight: bold !important;
}