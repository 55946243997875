.sa-segment {
    color: white;
    margin-left: 2.5rem !important;
    margin-right: .5rem !important;
}

.sa-dismiss-btn {
    color: white !important;
    padding: 0 !important;
    background-color: transparent !important;
    position: absolute;
    top: 5px;
    right: 0;
}

#sa-rail {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
    z-index: 100;
    height: 1rem;
}