.empty-row{
    color: white !important;
}

.rt-noData{
    position: absolute;
    left: 47%;
    top: 49%;
    color: rgba(0,0,0,.5);
    background: hsla(0,0%,100%,.8);
    padding: 10px;
    z-index: 1;
}

#pagination-row{
    box-shadow: 0 0 15px 0 rgba(0,0,0,.1);
    display: flex;
    border-top: none;
    padding-top: .5em;
    padding-bottom: .5em;
    border-radius: 0;
}

#pagination-row .previous{
    flex: 1 1 !important;
}

#pagination-row .next{
    flex: 1 1 !important;
}

#pagination-row .center {
    flex: 1.5 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#page-number-span{
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
}

#page-input{
    width: 70px;
}

#pagination-row button {
    height: 100%;
    width: 100%;
    padding: 0;
}

#pagination-cell{
    overflow: visible;
    padding-bottom: .3rem;
    padding-top: .3rem;
}

.pagination-button{
    height: 2.75rem;
}

.ui.table tbody tr:nth-child(2n) td{
    background-color: rgba(0,0,0,0.03)
}

.ui.table thead th{
    background-color: rgba(0,0,0,0.03) !important;
}

#super-table{
    min-width: 90em;
    border-radius: 0;
    width: auto !important;
    margin-bottom: 0;
    table-layout: fixed;
}

#super-table.no-rows{
    table-layout: unset;
}

.table-header-cell{
    padding: .4em !important;
}

.table-header-cell.column-name{
    font-weight: normal !important
}

#column-header {
    box-shadow: 0 2px 15px 0 rgba(0,0,0,.15);
    display: inline-flex !important;
    width: 100%;
}

.bottom-margin {
    margin-bottom: 1rem !important;
    width: 100%;
}

.resizer {
    display: inline-block;
    width: 36px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none;
}

#paginated-table-wrapper{
    display: inline-grid;
    width: 100%;
}

#table-wrapper{
    display: inline-grid;
    overflow: auto;
}